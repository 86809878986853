import type { SerializeFrom } from '@remix-run/cloudflare';
import { useMatches } from '@remix-run/react';
import { useMemo } from 'react';
import invariant from '../tiny-invariant';

type RouteId = string;

export function useRouteData<DataFunction>(routeId: RouteId) {
  return useMatchesData<SerializeFrom<DataFunction>>(routeId, true);
}

export function useOptionalRouteData<DataFunction>(routeId: RouteId) {
  return useMatchesData<SerializeFrom<DataFunction>>(routeId);
}

// overload 1 (like useRouteData)
// isRequired: true -> T
export function useMatchesData<T = Record<string, unknown>>(
  routeId: RouteId,
  isRequired: true
): T;

// overload 2 (like useOptionalRouteData)
// isRequired: false | undefined -> T | undefined
export function useMatchesData<T = Record<string, unknown>>(
  routeId: RouteId,
  isRequired?: false
): T | undefined;

// implementation (like existing useMatchesData)
// isRequired: boolean | undefined (default to false) -> T | undefined
export function useMatchesData<T = Record<string, unknown>>(
  routeId: RouteId,
  isRequired = false
): T | undefined {
  const matchingRoutes = useMatches();

  // the root loader is always the first match in remix
  const rootMatch = matchingRoutes[0];
  const appContext = rootMatch?.data;
  const isMissingContext = typeof appContext === 'undefined';
  if (isMissingContext) {
    if (isRequired) invariant(isMissingContext, 'Missing app context');
    return undefined;
  }

  // early exit if they want `root`'s data since we already have it
  if (routeId === 'root') return appContext as unknown as T;

  const { routeIds } = appContext;
  const isValidRouteId = useMemo(
    () => routeIds.includes(routeId),
    [routeIds, routeId]
  );
  invariant(isValidRouteId, `Unknown routeId '${routeId}'`);

  const routeMatch = useMemo(
    () => matchingRoutes.find((route) => route.id === routeId),
    [matchingRoutes, routeId]
  );
  return routeMatch?.data as unknown as T;
}
